<template>
  <div class="add_teacher">
    <el-card class="box-card">
      <el-form
        ref="addFromRef"
        :model="addFrom"
        :rules="addFromRules"
        label-width="110px"
      >
        <el-form-item label="活动名称:" prop="setName">
          <el-input
            v-model.number="addFrom.setName"
            style="width: 320px; margin-right: 10px"
          ></el-input>
        </el-form-item>
        <el-form-item label="发放方式:">
          <!-- 单选 -->
          <el-radio v-model="addFrom.isAuto" :label="1">审核</el-radio>
          <el-radio v-model="addFrom.isAuto" :label="2">自动</el-radio>
        </el-form-item>

        <el-form-item label="活动时间:" prop="activityTime">
          <el-date-picker
            style="width: 320px"
            v-model="addFrom.activityTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="领取有效期:" prop="collectionDate">
          <el-date-picker
            v-model="addFrom.collectionDate"
            style="width: 320px"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动周期:" prop="cycle">
          <el-input
            v-model.number="addFrom.cycle"
            style="width: 320px; margin-right: 10px"
          ></el-input
          ><span>天</span>
        </el-form-item>
        <el-form-item label="奖品使用时间:" prop="useDay">
          <el-input
            v-model.number="addFrom.useDay"
            style="width: 320px; margin-right: 10px"
          ></el-input
          ><span>天</span>
        </el-form-item>
        <el-form-item label="是否可叠加:">
          <el-radio v-model="addFrom.isAdd" :label="1">是</el-radio>
          <el-radio v-model="addFrom.isAdd" :label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="状态:">
          <el-radio v-model="addFrom.status" :label="1">正常</el-radio>
          <el-radio v-model="addFrom.status" :label="2">禁用</el-radio>
        </el-form-item>
        <el-form-item label="活动规则详情">
          <VueUeditorWrap
            v-model="addFrom.details"
            :config="myConfig"
          ></VueUeditorWrap>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import {
  inviteSetInfo,
  inviteSet,
  addInvite,
  inviteSetInfoRegister,
  inviteSetRegister,
  addInviteRegister,
  inviteSetInfoConsumption,
  inviteSetConsumption,
  addInviteConsumption
} from "../../api/invite.js";
import VueUeditorWrap from "vue-ueditor-wrap";

export default {
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度

        // serverUrl: "/api/config",
        // UEDITOR_HOME_URL: "/UEditor/",

        UEDITOR_HOME_URL: "/static/UEditor/", //打包
        serverUrl: "/config", //打包
        maximumWords: "100000000",
      },
      addFrom: {
        isAuto: 1,
        addTime: "",
        beginTime: "",
        endTime: "",
        cycle: "",
        isAdd: 1,
        status: 1,
        details: null,
        collectionDate: [],
        useDay: "",
        activityTime: [],
        setName: "",
      },
      addFromRules: {
        setName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
        ],
        activityTime: [
          { required: true, message: "请选择活动时间", trigger: "blur" },
        ],
        collectionDate: [
          { required: true, message: "请选择有效期", trigger: "blur" },
        ],
        cycle: [{ required: true, message: "请输入活动周期", trigger: "blur" }],
        useDay: [
          { required: true, message: "请输入奖品使用时间", trigger: "blur" },
        ],
      },
      type: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.$route.query.setId) {
      this.getDetails();
    }
  },
  methods: {
    // handleAvatarSuccess(res, file, type) {
    //   this.addFrom.headImgUrl = file.response.data[0].src;
    // },
    submitForm() {
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        // if (this.isSubmit) return;
        // this.isSubmit = true;
        this.addFrom.beginTime = this.addFrom.collectionDate[0];
        this.addFrom.endTime = this.addFrom.collectionDate[1];
        this.addFrom.addTime = this.addFrom.activityTime[0];
        this.addFrom.lastTime = this.addFrom.activityTime[1];
        let demaed = "";
        delete this.addFrom.collectionDate;
        delete this.addFrom.activityTime;
        if (this.addFrom.setId) {
          demaed = this.type == "order" ? inviteSet : this.type == "register"? inviteSetRegister : inviteSetConsumption;
        } else {
          demaed = this.type == "order" ? addInvite : this.type == "register"? addInviteRegister: addInviteConsumption;
        }
        const { data } = await demaed(this.addFrom);
        // this.isSubmit = false;
        if (data.code == 0) {
          this.$message.success(data.msg);
          // this.getDetails();
          this.goBack();
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
    getDetails() {
      let setId = this.$route.query.setId;
      let demaad = this.type == "order" ? inviteSetInfo : this.type == "register"?inviteSetInfoRegister:inviteSetInfoConsumption;
      demaad({ setId }).then((res) => {
        if (res.data.code == 0) {
          Object.keys(this.addFrom).forEach((key) => {
            this.addFrom[key] = res.data.data[key];
          });
          this.addFrom.collectionDate = [
            res.data.data.beginTime,
            res.data.data.endTime,
          ];
          this.addFrom.activityTime = [
            res.data.data.addTime,
            res.data.data.lastTime,
          ];
          this.addFrom.setId = setId;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>
